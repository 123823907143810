import React, { Component, useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { withRouter } from "react-router";

import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import { connect } from "react-redux";

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import Button from '@mui/material/Button';


import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';


// import { actionAddTEntry } from "../../reduxActions/redActions";



 
function BautagebuchEintrag () {

    const { uuid } = useParams(); 
    const navigate = useNavigate(); 

    const { token } = useAuth();

    const API_URL = process.env.REACT_APP_BACKEND_URL;


    const test = ["Klar", "Wolkig", "LeichterRegen", "Regen", "StarkerRegen", "Frost", "Schnee"];

    const [projectUUID, setprojectUUID] = useState(uuid);

    const [date, sethandleChangeDate] = useState(null);
    const [temperatur, sethandleChangeTemperatur] = useState(null);    
    const [wetter, sethandleChangeWetter] = useState(null);
    const [vorkommnisse, sethandleChangeVorkomnisse] = useState(null);
    const [behinderungen, sethandleChangeBehinderungen] = useState(null);
    const [leistungsaenderungen, sethandleChangeLeistungsaenderungen] = useState(null);


    

    const handleOnClick = () => {

        let state = {

            date: dayjs(date),
            finished: false,
            projectID: projectUUID,
            temperatur: temperatur, 
            wetter: wetter,
            vorkommnisse: vorkommnisse, 
            behinderungen: behinderungen, 
            leistungsaenderungen: leistungsaenderungen

        };


        let url  = API_URL+"/api/bautagebuch/"+uuid; 
        console.log(url);
        
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': `Token ${token}` }, 
            body: JSON.stringify(state),
        };
 
        fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                
                    throw new Error('Fehler beim Senden der Daten');

                }
                
                alert("Der Tagebucheintrag wurde erfolgreich eingetragen");

                navigate('/bautagebuchListe/'+uuid);

                return response.json();
            });
        

    };





        return (
            <>
                <h3>Bautagesbericht</h3>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Datum des Eintrags" 
                        defaultValue={date} 
                        onChange={event => sethandleChangeDate(event.target)} />
                </LocalizationProvider>

                <br />

                <TextField 
                    id="outlined-basic" 
                    label="Temperatur" 
                    variant="outlined" 
                    value={temperatur}
                    onChange={event => sethandleChangeTemperatur(event.target.value)}/>

                <br />

                <InputLabel id="demo-simple-select-label">Wetter</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="none"
                label="Wetter"
                onChange={sethandleChangeWetter}
                >
                <MenuItem value="none">-</MenuItem>
                <MenuItem value="klar">Klar</MenuItem>
                <MenuItem value="wolkig">Wolkig</MenuItem>
                <MenuItem value="leichterRegen">Leichter Regen</MenuItem>
                <MenuItem value="regen">Regen</MenuItem>
                <MenuItem value="starkerRegen">Starker Regen</MenuItem>
                <MenuItem value="frost">Frost</MenuItem>
                <MenuItem value="schnee">Schnee</MenuItem>
                </Select>

                <br />

                <TextField
                    id="outlined-multiline-static"
                    label="Besondere Vorkomnisse"
                    multiline
                    rows={5}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => sethandleChangeVorkomnisse(event.target.value)}
                    />

                <br />


                <TextField
                    id="outlined-multiline-static"
                    label="Behinderungen"
                    multiline
                    rows={5}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => sethandleChangeBehinderungen(event.target.value)}
                    />

                <br />

                <TextField
                    id="outlined-multiline-static"
                    label="Leistungsänderungen"
                    multiline
                    rows={5}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => sethandleChangeLeistungsaenderungen(event.target.value)}
                    />

                <br /><br />

                <Button variant="contained" onClick={handleOnClick}>Eintragen</Button>

        </>
        );

};

export default BautagebuchEintrag;