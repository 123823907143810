import React, { Component, useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Box from '@mui/material/Box';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';

import { useAuth } from '../../hooks/AuthContext';



function valuetext(value) {
    return `${value}°C`;
  }




export default function TaskNew() {

    const { uuid } = useParams();

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????    



    let [name, setName] = useState("");
    let [dependencies, setDependencies] = useState(0);
    let [start, setStart] = useState(null); //(dayjs("0000-00-00", "YYYY-MM-DD").format('YYYY-MM-DD'));
    let [end, setEnd] = useState(null);// (dayjs("0000-00-00", "YYYY-MM-DD").format('YYYY-MM-DD'));
    let [duration, setDuration] = useState(0);
    let [progress, setProgress] = useState(0);
    let [type, setType] = useState("task");
    let [displayOrder, setDisplayOrder] = useState(0);
    let [note, setNote] = useState("-");

    const { token } = useAuth();

    let handleName          = (event) => { setName(event.target.value); };
    let handleDependencies  = (event) => { setDependencies(event.target.value); };
    //setProjectID(1);
    let handleStart         = (event) => { 
        let formattedDate = event.format('YYYY-MM-DD');
        setStart(formattedDate); };
    let handleEnd           = (event) => { 
        let formattedDate = event.format('YYYY-MM-DD');
        setEnd(formattedDate); };
    let handleDuration      = (event) => { setDuration(event.target.value); };
    let handleProgress      = (event) => { setProgress(event.target.value); };
    let handleType          = (event) => { setType(event.target.value); };
    //setDisplayOrder(0);
    let handleNote          = (event) => { setNote(event.target.value); };


    let handleOnClick = () => {

        let data = {"name": name, 
                    "dependencies": dependencies,
                    "projectmodellink": uuid,
                    "start": start,
                    "end": end,
                    "duration": parseFloat(duration),
                    "progress":parseFloat(progress),
                    "type":type,
                    "displayOrder": displayOrder,
                    "note":note
                
                };

        console.log(data);

        let url  = API_URL+"/api/tasks/endpoint/"+uuid;
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
              },
            body: JSON.stringify(data),
        };

        fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                throw new Error('Fehler beim Senden der Daten');
                }
                alert("Task erfolgreich geschrieben");
                return response.json();
            });
        

    };
 

    return (
        <>
        
        <h3>Neuer Task</h3>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                label="Startdatum" 
                defaultValue=""
                onChange={handleStart}/>
            <DatePicker 
                label="Enddatum" 
                defaultValue=""
                onChange={handleEnd}/>
        </LocalizationProvider>
        <br />

        <TextField
                    id="outlined-multiline-static"
                    label="Name"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={handleName}
                    />
        <TextField
                    id="outlined-multiline-static"
                    label="Beschreibung"
                    multiline
                    rows={4}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={handleNote}
                    />
        <br />
        <TextField 
                    id="outlined-basic" 
                    label="Dauer" 
                    variant="outlined" 
                    value=""
                    onChange={handleDuration}/>
        <br />


        <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Typ"
                onChange={handleType}
                >
                <MenuItem value="project">Projekt</MenuItem>
                <MenuItem value="task">Vorgang</MenuItem>
                <MenuItem value="milestone">Meilenstein</MenuItem>
                </Select>

        <br />
        
        <Button variant="contained" onClick={handleOnClick}>Eintragen</Button>

        </>
        );

}