import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";


import { AutoForm, AutoField, SubmitField, ErrorsField } from 'uniforms-mui-5';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';

import { useAuth } from '../../hooks/AuthContext';


const schema = {
    title: 'ifcElements',
    type: 'object',
    properties: {
      ifcID: { type: 'string' },
      ifcType: { type: 'string' },
      ifcName: { type: 'string' },
      ifcObjectType: { type: 'string' },
      ifcDescription: { type: 'string' },
    },
    required: ['ifcID', 'ifcType'],
  };

  const bridge = new JSONSchemaBridge(schema, () => {});


function EditDialog(props) {

  const { uuid } = useParams(); 

  const { token } = useAuth();

    let API_URL = process.env.REACT_APP_BACKEND_URL;
    let endpointUrl = "/api/ifcelements/"+uuid


    let handleSubmit = (data) => {

        console.log(data);

        let url  = API_URL+endpointUrl;
        console.log(url);
        
        let requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
            body: JSON.stringify(data),
        };
 
        fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                throw new Error('Fehler beim Senden der Daten');
                }
                return response.json();
            });
        

    };

    
      return (
        <AutoForm schema={bridge} onSubmit={handleSubmit}>
          <AutoField name="ifcID" />
          <AutoField name="ifcType" />
          <AutoField name="ifcName" />
          <AutoField name="ifcObjectType" />
          <AutoField name="ifcDescription" />
          {/* Vergessen Sie nicht, für jedes Feld im Schema ein AutoField hinzuzufügen */}
          <ErrorsField />
          <SubmitField value="Submit" />
        </AutoForm>
      );
    }


export default EditDialog;