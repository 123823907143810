// CustomDateInput.js
import React from 'react';
import { connectField } from 'uniforms';
import TextField from '@mui/material/TextField';


const formatDate = (date) => {
  if (!date) return '';

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};


const parseDate = (dateString) => {
  const [day, month, year] = dateString.split('.');
  if (!day || !month || !year) return null;

  return new Date(year, month - 1, day);
};


const CustomDateInput = ({ onChange, value, ...props }) => {

  const handleDateChange = (event) => {
    const newValue = parseDate(event.target.value);
    onChange(newValue);
  };

  return (
    <TextField
      {...props}
      value={formatDate(value)}
      onChange={handleDateChange}
      fullWidth
    />
  );
};

export default connectField(CustomDateInput);
