import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import Divider from '@mui/material/Divider';

import { Link } from "react-router-dom";

import { useProjectContext } from '../../hooks/ProjectContext';



export default function ProjectNavigation(props) {

  // Ich denke hier müsste die aktuelle Projekt UUID mit
  // den Aufklappen der jeweiligen Projektnavigation verändert werden
  // aber um voranzukommen, ist es für heute egal

  // console.log(props.project.id);

  const { projectUUID, setProjectUUID } = useProjectContext();

  setProjectUUID(props.project.id);

  // const projectUUID = props.project.id;
  
  var UUID = props.project.id;


  /**
   * Dieser Teil sollte eigentlich auch vom Backend stammen
   * Hierdurch kann man für jedes Projekt und jedem Nutzer individuell
   * Apps zuweisen
   */

  const meneuarray = [
    
                    
                    
                     /* {
                      id: 0,
                      icon: "HomeWorkIcon",
                      linkto: "/bim/"+UUID,
                      textprimaray: "3D Viewer",
                      textsecundary: "ACHTUNG erw. Mokup"
                    },*/
                    
                    {
                      id: 1,
                      icon: "HomeWorkIcon",
                      linkto: "/planviewer/"+UUID,
                      textprimaray: "Planbetrachter",
                      textsecundary: "Alpha"
                    },

                    {
                      id: 2,
                      icon: "HomeWorkIcon",
                      linkto: "/myfileupload/"+UUID,
                      textprimaray: "Meine Dateien",
                      textsecundary: "Alpha"
                    },
                    /*
                    {
                      id: 2,
                      icon: "BurstModeIcon",
                      linkto: "/webcam/"+urlext,
                      textprimaray: "Webcam",
                      textsecundary: "none"
                    },
                    */
                    {
                      id: 3,
                      icon: "MenuBookIcon",
                      linkto: "/ifcelementsListe/"+UUID,
                      textprimaray: "Bauteile",
                      textsecundary: "IFC Elemente"
                    },
                    {
                      id: 4,
                      icon: "MenuBookIcon",
                      linkto: "/bautagebuchListe/"+UUID,
                      textprimaray: "Bautagebuch",
                      textsecundary: "Einträge"
                    },
                    {
                      id: 5,
                      icon: "MenuBookIcon",
                      linkto: "/positionenListe/"+UUID,
                      textprimaray: "Positionen",
                      textsecundary: ""
                    },
                    {
                      id: 6,
                      icon: "AssignmentTurnedInIcon",
                      linkto: "/taskList/"+UUID,
                      textprimaray: "Terminplanung",
                      textsecundary: "Einträge"
                    },
                    {
                      id: 7,
                      icon: "AssignmentTurnedInIcon",
                      linkto: "/taskGantt/"+UUID,
                      textprimaray: "Zeitenplan",
                      textsecundary: "Gantt"
                    },

                    /**
                    {
                      id: 8,
                      icon: "AssignmentTurnedInIcon",
                      linkto: "/taskGantt/"+urlext,
                      textprimaray: "Anliegen Management",
                      textsecundary: "Issues - Konzeptphase"
                    },*/

                    /**
                    {
                      id: 9,
                      icon: "AssignmentTurnedInIcon",
                      linkto: "/taskGantt/"+urlext,
                      textprimaray: "Dateien",
                      textsecundary: "Konzeptphase"
                    },*/

                    /**
                    {
                      id: 10,
                      visible: true,
                      version: "PRE",
                      icon: "AssignmentTurnedInIcon",
                      linkto: "/taskGantt/"+urlext,
                      textprimaray: "Messages",
                      textsecundary: "Idee"
                    },*/


                    {
                      id: 11,
                      visible: true,
                      version: "PRE",
                      icon: "AssignmentTurnedInIcon",
                      linkto: "/gis/"+UUID,
                      textprimaray: "GIS",
                      textsecundary: "Alpha"
                    },


                  
                  ];


  return (
    <>


    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

    {meneuarray && meneuarray.map((meneuentry) => ( 

      <>

        <Link to={ meneuentry.linkto }>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BurstModeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={ meneuentry.textprimaray } secondary={ meneuentry.textsecundary } />
        </ListItem>
        </Link>

      <Divider variant="inset" component="li" />

      </>
    
    ))}



    </List>

    </>
  );
}
