import React, { Component } from "react";

class View extends Component {

    render() {

        return (
            <>
                Admin
            </>
        );

    }


}

export default View;