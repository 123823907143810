import React, { Component, useState } from 'react';
import ImageList from "../elements/WebcamImageList";
import { Link, useParams } from "react-router-dom";


function Webcam () {

    const { uuid } = useParams(); 


    return (
        <>
        <ImageList />
        </>
    );


}


export default Webcam;