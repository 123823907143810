import React, { Component } from 'react';

import AccordionProjects from "./NavigationAccordion_002.js";


class LayoutNavigation extends Component {

    render() {

        return (
          <>

          <AccordionProjects></AccordionProjects>

          </>


        );

    }


}


export default LayoutNavigation;