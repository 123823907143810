import React, { useState } from 'react';

import { Button, TextField, Container, Typography } from '@mui/material';

import axios from 'axios';

import { useAuth } from '../../hooks/AuthContext';



const LoginComponent = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { setIsAuthenticated, login } = useAuth();

    /*
    const handleLogin = async () => {
        try {
            // Hier die API-Anfrage zum Einloggen
            
            
            const response = await axios.post('/api/login', { username, password });
            if (response.status === 200) {
                setIsAuthenticated(true);
            }

        } catch (error) {
            console.error('Fehler beim Einloggen:', error);
        }
    };*/

    const handleLogin = (event) => {
        event.preventDefault();
        login(username, password);
      };    

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                Anmelden
            </Typography>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Passwort"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleLogin}
            >
                Anmelden
            </Button>
        </Container>
    );
};

export default LoginComponent;
