import { useState, useContext, createContext, useEffect } from "react";
import axios from 'axios';

const ProjectContext = createContext(null); 


// Ein Provider ist eine Art Wrapper, der Elemete in sich "Wrappt"
// Habe den ganzen Spaß vereinfacht, indem ich den Contextprovider eingesetzt habe
// Keine Ahnung ob das eine gute Idee gewesen ist.
// Vorher habe ich das ganze mt REDUX umgesetzt
// Nun ist jedoch REDUX in der App nicht mehr vorhanden
// 2023-06-06

export function ProjectProvider ({ children }) {

    const API_URL = process.env.REACT_APP_BACKEND_URL;


    /**
     * Festlegen der States für den Hook
     */
    const [ actualProject, setactualProject ] = useState(0);
    
    // ACHTUNG wieder mal statisch gecodet
    const [ projectID, setProjectID ] = useState(1);
    const [ projectUUID, setProjectUUID ] = useState("833a50a7-4ddc-459b-b575-0210d97a286c");
    
    const [ projectCoordinates, setProjectCoordinates] = useState({ latitude: false, longitude: false });

    const [ projectsList, setProjectsList ] = useState(false);

    /**
     * Nun werden die Werte initaialisiert, soweit möglich
     */

    let url = API_URL+"/api/projects/endpoint";

    /**
     * Einlesen der Projektliste
     */

    useEffect(() => {
        
        axios.get(url)
            .then(response => {

                const projectList = response.data;
                // console.log("Projektliste");
                // console.log(projectList);
                setProjectsList(projectList.datas);

            })
      
      .catch(error => {

        console.log(error.message);

      });



      }, []);

    return(

        <ProjectContext.Provider value={{   actualProject, setactualProject, 
                                            projectCoordinates, setProjectCoordinates, 
                                            projectID, setProjectID,
                                            projectUUID, setProjectUUID,
                                            
                                            projectsList, setProjectsList}}>

            { children }

        </ProjectContext.Provider>

    );

}



export function useProjectContext() {
    return useContext(ProjectContext);
  }


