import React, { useState, useEffect } from 'react';

import { Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Import Icons
import EditIcon from '@mui/icons-material/Edit';

// Import Eigene
import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';


function PositionenDetail (props) {

    const API_URL = process.env.REACT_APP_BACKEND_URL;

    const { token } = useAuth();

    const { id } = useParams();
    const { uuid } = useParams(); 


    const [positionDetail, setPositionDetail] = useState(null);
    
    var ENDPOINT_URL = "/api/bautagebuch/positionen/"+uuid+"/"+id;

    useEffect(() => {

        
        const getData = async () => {
  
  
                FetchAPIData(ENDPOINT_URL, {}, token).then(response => {
  
                  setPositionDetail(response.datas);
                  console.log(response);
                  console.log(positionDetail);
                  
                  });
        }
  
        getData();
  
    }, []);


    return (

        <Box sx={{ width: '100%', minWidth: '1000px' }}> 

        <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Detailansicht
        </Typography>

        {positionDetail && positionDetail.map((detail) => (
          
          <Table style={{ width: '100%' }}>
            <TableHead>
            <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Wert</TableCell>
            <TableCell>Edit</TableCell>
            </TableRow>
            </TableHead>

            <TableBody>

            <TableRow key="test">
                <TableCell>Nr.</TableCell>
                <TableCell>{detail.itemnr}</TableCell>
                <TableCell><EditIcon style={{ color: '#eeeeee' }}/></TableCell>
            </TableRow>

            <TableRow key="test">
                <TableCell>Name</TableCell>
                <TableCell>{detail.name}</TableCell>
                <TableCell><EditIcon style={{ color: '#eeeeee' }} /></TableCell>
            </TableRow>

            <TableRow key="test">
                <TableCell>Beschreibung</TableCell>
                <TableCell>{detail.note}</TableCell>
                <TableCell><EditIcon style={{ color: '#eeeeee' }} /></TableCell>
            </TableRow>

            <TableRow key="test">
                <TableCell>Einheit</TableCell>
                <TableCell>{detail.unit}</TableCell>
                <TableCell><EditIcon style={{ color: '#eeeeee' }} /></TableCell>
            </TableRow>

            <TableRow key="test">
                <TableCell>Anzahl</TableCell>
                <TableCell>{detail.quantity}</TableCell>
                <TableCell><EditIcon style={{ color: '#eeeeee' }} /></TableCell>
            </TableRow>

            <TableRow key="test">
                <TableCell>Preis</TableCell>
                <TableCell>{detail.unitprice}</TableCell>
                <TableCell><EditIcon style={{ color: '#eeeeee' }} /></TableCell>
            </TableRow>

            <TableRow key="test">
                <TableCell>Gesamtpreis</TableCell>
                <TableCell>{detail.totalprice}</TableCell>
                <TableCell><EditIcon style={{ color: '#eeeeee' }} /></TableCell>
            </TableRow>

            </TableBody>
          </Table>
          ))}

      </ Box>

    );

}


export default PositionenDetail;