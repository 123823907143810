import * as React from 'react';
import { Link, useParams } from "react-router-dom";

// import Liste from "../elements/ListeBautagebuchVer002";
import Universaltable from '../elements/universaltable_002';
import AddButton from "../elements/ButtonAddnew";

// https://stackoverflow.com/questions/53219113/where-can-i-make-api-call-with-hooks-in-react

function TypeListe() {

    const { uuid } = useParams();

    let rows = [
        {label : "Nr",
        field : "id"},

        {label : "Name",
        field : "name" },

        {label : "Anfang",
        field : "start",
        type : "date"},

        {label : "Ende",
        field : "end",
        type : "date"},
        ];

    return (
        <>        
        <h2>Tasks</h2>

        <Universaltable rows={rows} url={`/api/tasks/endpoint/${uuid}`} linkurl={`/taskLink/${uuid}`} titel="Tasks Elements"/>
        <AddButton link={`/taskNew/${uuid}`} />
        </>
    );

};

export default TypeListe; 