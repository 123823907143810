import React, { useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";

// import { connect } from "react-redux";


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

 
import AddButton from "../elements/ButtonAddnew";

import { useProjectContext } from '../../hooks/ProjectContext';



const ProjektAuswahl = (switchid) => {

  // console.log(switchid);
  var stringswitchid = String(switchid.id);

  const { projectUUID, setProjectUUID } = useProjectContext();
  
  const handleRadioButtonClick = () => {

    setProjectUUID(stringswitchid);

    alert(stringswitchid + "|" + projectUUID);
  };
  
  // console.log(projectUUID);
  // console.log(typeof(projectUUID))
  // console.log(id.id);
  // console.log(typeof(id))

  if ( stringswitchid == projectUUID ) {

    return(<div><TaskAltIcon /></div>)

  }

  else {

    return(<div><RadioButtonUncheckedIcon onClick={ handleRadioButtonClick }/></div>)

  }

}



const ProjectList = () => {

  const { projectUUID } = useProjectContext();
  const { projectsList } = useProjectContext();
  const { getProjects } = useProjectContext();
  const { setProjectsList } = useProjectContext();
  const { setProjectsRenew } = useProjectContext();

  // console.log(projectUUID);

  
  // useEffect Hook ohne Abhängigkeiten, wird einmalig beim Mounten der Komponente ausgeführt
  useEffect(() => {
    // Setzen von projectsRenew auf true, um die Aktualisierung der Projekte auszulösen
    setProjectsRenew(true);
  }, []); // Leere Abhängigkeitsliste bedeutet, dass dieser Code nur beim Mounten ausgeführt wird 



    let StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

    let StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));


    /**
     * 
     * { projectList && projectList.map(project => {
                return <p>{project.name}</p>
            }) }
     * 
     */

    
    
    return (
        <>
            <h3>Projektliste</h3>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                    <StyledTableCell>id</StyledTableCell>
                    <StyledTableCell align="right">Name</StyledTableCell>
                    <StyledTableCell align="right">Bemerkung</StyledTableCell>
                    <StyledTableCell align="right">-</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {projectsList && projectsList.map((row) => (
                    <StyledTableRow key={ row.id }>
                        <StyledTableCell component="th" scope="row">
                        { row.id }
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.name}</StyledTableCell>
                        <StyledTableCell align="right">{row.note}</StyledTableCell>
                        <StyledTableCell align="right"><ProjektAuswahl id={ row.id }/></StyledTableCell>

                    </StyledTableRow>
                    ))}

                </TableBody>
                </Table>
            </TableContainer>

            <AddButton link="/ProjectNew"/>

        </>
    );

};


  
  export default ProjectList;