import React, { useState, useEffect } from 'react';

import { Link, useParams } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import dayjs from 'dayjs';

import { useProjectContext } from '../../hooks/useProject';

import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';


function formatDate(date) {
    const format = 'DD.MM.YYYY'; // gewünschtes Format
    return dayjs(date).format(format);
  }




function ShowBBookDetail (props) {

    const API_URL = process.env.REACT_APP_BACKEND_URL;

    const { id } = useParams(); 
    const { uuid } = useParams(); 

    const { token } = useAuth();

    const { projectUUID } = useProjectContext();

    const [BautagebuchDetails, setBautagebuchDetails] = useState(null);
    const [elementsList, setElementsList] = useState(null);

    let url = "/api/bautagebuch/positionen/"+projectUUID+"/"+id;
     
            useEffect(() => {

        
                const getData = async () => {

                        // console.log(token);
          
          
                        FetchAPIData(props.url, {}, token).then(response => {
          
                          setElementsList(response.datas);
                          console.log(response);
                          console.log(elementsList);
                          
                          });
                }
          
                getData();
          
            }, []);

 
    return (

        <>

        {BautagebuchDetails && BautagebuchDetails.map((BautagebuchDetail) => (

            <Paper elevation={3} style={{ padding: '24px', maxWidth: '1000px', margin: '16px auto' }}>
            
            
                <Typography variant="h6" gutterBottom>Bautagebucheintrag vom { formatDate(BautagebuchDetail.date) }</Typography>
                        <br />

                <Typography variant="body1" paragraph>ID: {BautagebuchDetail.id}</Typography>
                <Divider style={{ margin: '16px 0' }} />
                <Typography variant="body1" paragraph>Datum: {formatDate(BautagebuchDetail.date)}</Typography>
                <Typography variant="body1" paragraph>Vorkommnisse: {BautagebuchDetail.vorkommnisse}</Typography>
                <Typography variant="body1" paragraph>Behinderungen: {BautagebuchDetail.behinderungen}</Typography>
                <Typography variant="body1" paragraph>Leistungsänderungen: {BautagebuchDetail.leistungsaenderungen}</Typography>
                <Divider style={{ margin: '16px 0' }} />
                <Typography variant="body1" paragraph>Verknüpfungen:  Sind noch nicht freigeschaltet</Typography>

              </Paper>
          ))}

        </>

    );

}


export default ShowBBookDetail;