import React, { useState, useEffect, Suspense } from 'react';
import { Link, useParams } from "react-router-dom";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';


import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';




const ImageWithSymbols = () => {


  const { token } = useAuth();
  const navigate = useNavigate(); 

  const [userFiles, setUserFiles] = useState([]);
  const [userFilesIsLoading, setUserFilesIsLoading] = useState(true);

  const [filedata, setFiledata] = useState([]);

  const { id } = useParams();
  const { uuid } = useParams(); 

  const [positionIcon, setPositionIcon] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menueOpen, setMenueOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [hoveredItem, setHoveredItem] = useState(null);
  
  const [newSymbolPosition, setNewSymbolPosition] = useState({ x: 0, y: 0 });

  const API_URL = process.env.REACT_APP_BACKEND_URL;

  var ENDPOINT_URL = "/api/iconplacement/"+uuid;

  useEffect(() => {

      
      const getData = async () => {


              FetchAPIData(ENDPOINT_URL, {}, token).then(response => {

                setPositionIcon(response);
                // console.log(response);
                // console.log("Iconplacement");
                // console.log(positionIcon);
                
                });
      }

      getData();

  }, []);


  let url = "/api/files/get/"+uuid;


  useEffect(() => {

        
    const getData = async () => {

        // console.log("UserFilesList");      
        // console.log(url);

            FetchAPIData(url, {}, token).then(response => {

              setUserFiles(response);
              // console.log("UserFilesList");
              // console.log(response);
              // console.log(userFiles);

              setUserFilesIsLoading(false);
              
              });
    }

    getData();

}, []);

  
  const handleOpen = () => {
    setMenueOpen(true);
  };

  const handleClose = () => {
    setMenueOpen(false);
  };




  const handleContextMenu = (event) => {
    event.preventDefault();
    // Öffnen Sie hier Ihr Kontextmenü
    const image = event.target;
    const { offsetX, offsetY } = event.nativeEvent;

    const imageWidth = image.offsetWidth;
    const imageHeight = image.offsetHeight;

    const clickX = (offsetX / imageWidth) * 100;
    const clickY = (offsetY / imageHeight) * 100;

    setNewSymbolPosition({ x: clickX, y: clickY });

    event.preventDefault();
    setAnchorEl(event.currentTarget);

    setMenueOpen(true);

    // console.log('Klickposition:', clickX, clickY);
  };


  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);

    // Nun sollte die Position gespeichert werden

    let url  = API_URL+"/api/iconplacement/"+uuid+"/"+item;

    console.log(url);

    let requestOptions = {

      method: 'POST',
      headers: { 'Content-Type': 'application/json',
      'Authorization': `Token ${token}` },
      body: JSON.stringify(newSymbolPosition),
  };

  fetch(url, requestOptions)

      .then((response) => {
          if (!response.ok) {
          
              throw new Error('Fehler beim Senden der Daten');

          }
          
          alert("Der Tagebucheintrag wurde erfolgreich eingetragen");

          navigate('/planviewer/'+uuid);

          return response.json();
      });
    
    
    setMenueOpen(false);

    
  };

  const handleSaveButtonClick = () => {
    // Speichern Sie hier den ausgewählten Eintrag
    console.log('Ausgewählter Eintrag:', selectedItem);
  };

  const handleMouseEnter = (itemId) => {
    setHoveredItem(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };


  const ShowMeneu = () => {

    return (
    <Modal
        open={menueOpen}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper style={{ padding: '2rem' }}>
          <Typography variant="h6">Wählen Sie die Datei aus, die Sie verknüpfen möchten</Typography>

          <List>
            {userFiles && userFiles.map(file => (
              <ListItem key={file.id} 
                        onClick={() => handleMenuItemClick(file.id)}
                        onMouseEnter={() => handleMouseEnter(file.id)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          backgroundColor: hoveredItem === file.id ? 'lightgray' : 'transparent',
                        }}>

                <ListItemText primary={file.name} />
            </ListItem>
            ))}
          </List>
        </Paper>
      </Modal>
      )

  }



const ShowSymbols = () => {


  const PlaceLinkIcon = (props) => {

      const foundFile = userFiles.find(file => file.id === props.fileuuid.userFilesmodellink);
      // console.log(foundFile.file);

      return(
        <>
          <a href={API_URL+foundFile.file} target="_new">
          <ImageIcon style={{ color: 'orange' }} />
          </a>
        </>
      );
  } // Ende PlaceLinkIcon

  
  return (
    <>
        {positionIcon && positionIcon.map((singleIcon) => (

                <div
                  style={{
                    position: 'absolute',
                    top: `${singleIcon.location.y}%`,
                    left: `${singleIcon.location.x}%`,
                  }}
                >
                  <PlaceLinkIcon fileuuid={singleIcon}/>
                </div>

        ))};

    </>
  );
}  

  if (userFilesIsLoading) {

    return (<div>Lade...</div>);

  }

  else {

    return (
      <>
      <div style={{ position: 'relative', width: '1000px', height: '1268px' }}
        onContextMenu={handleContextMenu}>
        <img
          src={API_URL+'/upload/userfiles/public/Erftstadt_EG.png'} // Pfad zum Bild
          alt="Bild"
          style={{ width: '100%', height: '100%' }}
        />

        <ShowSymbols />

      < ShowMeneu />
          <div
            style={{
              position: 'absolute',
              top: `${newSymbolPosition.y}%`,
              left: `${newSymbolPosition.x}%`,
            }}
          >
            {/* Hier das Symbol platzieren */}
            <ImageIcon style={{ color: 'orange' }} />
          </div>
        
      </div>
      </>
    );
  }
};

export default ImageWithSymbols;