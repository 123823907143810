import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

import UserFilesList from "../elements/Files_002.js"; 
import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';

const FileUpload = () => {

    const [file, setFile] = useState(null);
    const { uuid } = useParams(); 

    const { token } = useAuth();

    const API_URL = process.env.REACT_APP_BACKEND_URL;

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onUpload = async () => {

        let url  = API_URL+"/api/files/upload/"+uuid;
        console.log(url);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);

        try {
            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Token ${token}`
                  },
            });
            alert('Die Datei wurde erfolgreich auf den Server geladen.');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div>
            <UserFilesList projectUUID={uuid}/>
            <br />
            <input type="file" onChange={onFileChange} />
            <button onClick={onUpload}>Upload</button>
        </div>
    );
};

export default FileUpload;