import Ajv, { JSONSchemaType } from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';


    // Validator

    type FormData = {
        name: string;
        note: string;
        adress: string;
      };

    // Schema für uniforms
    const schema = {
    title: 'Lead Form',
    type: 'object',
    
    properties: {
        name : { type: 'string', description: 'Name des Projekts', },
        note: { type: 'string'},
        adress: { type: 'string'},
        
    },
    required: ['name']
    };


   
      

      
      const ajv = new Ajv({
        allErrors: true,
        useDefaults: true,
        keywords: ['uniforms'],
      });


      function createValidator<T>(schema: JSONSchemaType<T>) {
        const validator = ajv.compile(schema);
      
        return (model: Record<string, unknown>) => {

          validator(model);
          return validator.errors?.length ? { details: validator.errors } : null;
          
        };
      }
    
      
      
      const schemaValidator = createValidator(schema);
      
      export const bridge = new JSONSchemaBridge(schema, schemaValidator);
