import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


// Provider
import { AuthProvider } from './hooks/AuthContext';
import { ProjectProvider } from './hooks/ProjectContext';


import App from './App';




const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <AuthProvider>
  <ProjectProvider>

    <div>
      <App />
    </div>
  
  </ProjectProvider>
  </AuthProvider>
  
);


