import React, { useState, useEffect } from 'react';

// import { connect } from "react-redux";

import { Link, useParams } from "react-router-dom";

// REDUX
// import { useSelector } from 'react-redux';

import axios from 'axios';

// import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';

// import { fetchDatadropFromAPI } from '../../modules/bredic/apicalls';
import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';


import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LoupeIcon from '@mui/icons-material/Loupe';
import DeleteIcon from '@mui/icons-material/Delete';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { DeleteDialog } from "../elements/DeleteDialog";


import { useProjectContext } from '../../hooks/ProjectContext';
 
 

// Wird gerade nicht benötigt

/*
function formatDate(date) {
  const format = 'DD.MM.YYYY'; // gewünschtes Format
  return dayjs(date).format(format);
}*/

function GetValueFromObject(props) {

    // console.log("Object Key");
    // console.log(props.object);
    // console.log(props.dynamicKey);

    if (props.stencil) {

        // console.log("Gefunden!!!!!");

        let output =  (

            <TableCell align="left" ><Tooltip title={ props.object.note }><span>

                {(() => {
                      if (props.stencil.type === 'date') {
                        return dayjs(props.object[props.stencil.field]).format('DD.MM.YYYY');
                      } else {
                        return(
                          props.object[props.stencil.field]
                          )
                        
                      }
                    })()}

            </span></Tooltip></TableCell>
            
        );

        return output;
    }

    /**
     *               {dayjs(originalDate).format('DD.MM.YYYY')}
                {props.object[props.stencil.field]}
     */

    // console.log("VERWORFEN");
    return;
  }



function Universaltable (props) {

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const { token } = useAuth();

  const { projectUUID } = useProjectContext();

  const [open, setOpen] = useState(false); // Für die Löschen Sicherheitsabfrage  
  const [elementId, setElementId] = useState(false); // Für die zu löschende Element ID
  const [elementsList, setElementsList] = useState(null);

  // const projectActual = useSelector((state) => state.projectActual);
  
  // console.log("Aktuelles Projekt: ");
  // console.log(projectActual);
  // console.log(projectUUID);


    // console.log(props);
    const rows = props.rows;
    // console.log(rows);

    
    useEffect(() => {

        
      const getData = async () => {


              FetchAPIData(props.url, {}, token).then(response => {

                setElementsList(response.datas);
                console.log(response);
                console.log(elementsList);
                
                });
      }

      getData();

  }, []);



    const handleDelete = async (id) => { 


      // console.log("DELETE");
      // console.log(id);
      // Hier ist irgendein fehler drinnen

      /*

      let urlEndpoint= props.url // Wegen der Kompatibilität

      let url = API_URL+urlEndpoint;

      axios.delete(url, {
          headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer Ihr-Token'  // Falls erforderlich
          },
          data: { id: id },  // Falls erforderlich
      })
      .then((response) => {
      // console.log(response.data);
      })
      .catch((error) => {
      console.error('Error:', error);
      });
      */
  };


  const handleClickOpen = (elementId) => {

    setElementId(elementId);
    setOpen(true);

  };

  const handleClose = () => {

    setElementId(false);
    setOpen(false);

  };

  let DeleteDialog = (id) => {

    // console.log("DeleteDialog");
    // console.log(id);


    return(
      <Dialog open={open} onClose={handleClose}>

        <DialogTitle id="alert-dialog-title">{"Bist du sicher?"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, dass Sie diesen Datensatz löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>

        <DialogActions>

          <Button onClick={handleClose} variant="contained">
            Abbrechen
          </Button>

          <Button onClick={handleDelete(elementId)} variant="contained" autoFocus>
            Löschen
          </Button>

        </DialogActions>

      </Dialog>

  );

  }


    return (

        <>

        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>

                {rows && rows.map((row) => (
                    <TableCell align="left" key={row.id}>{row.label}</TableCell>
                ))}

              <TableCell align="right">-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
  
            {elementsList && elementsList.map((element) => (
              
              <TableRow
                key={element.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >

                {rows && rows.map((row) => (
                    <GetValueFromObject object={element} stencil={row} />
                ))}

            <TableCell align="right">

            
                <Link to={"/positionDetail/" + projectUUID + "/" + String(element.id)}>
                    <Tooltip title="Genauer ansehen"><LoupeIcon /></Tooltip>
                </Link>

                

                <Link to={props.linkurl +"/"+ String(element.id)}>
                <Tooltip title="Verlinkungen"><AddLinkIcon /></Tooltip>
                </Link>

            </TableCell>
              </TableRow>
            ))}
  
          </TableBody>
        </Table>
      </TableContainer>
      
       </>

    );

    /**
     * Habe ich erstmal rausgenommen
     *                 <Tooltip title="Datensatz Löschen">
                  <DeleteOutlineIcon 
                  onClick={() => handleClickOpen(element.id)} /></Tooltip>
                  <DeleteDialog id={element.id} />
     */

}


export default Universaltable;