// CustomDatePicker.js
import React from 'react';
import { connectField, useForm } from 'uniforms';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

const CustomDatePicker = (props) => {
  const { label, name, value } = props;
  const { onChange } = useForm();

  const handleChange = (newValue) => {
    onChange(name, newValue);
  };

  return (
    <DatePicker
      label={label}
      value={value}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} fullWidth />}
    />
  );
};

export default connectField(CustomDatePicker);
