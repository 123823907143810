import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";


import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';

import { useAuth } from '../../hooks/AuthContext';


 

function PositionenNew () {

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

    const { uuid } = useParams(); 

  
    const [name, setname] = useState("");
    const [quantity, setquantity] = useState("");
    const [unit, setunit] = useState("");
    const [note, setnote] = useState("");
    const [itemnr, setitemnr] = useState("");
    const [unitprice, setunitprice] = useState("");
    const [totalprice, settotalprice] = useState("");

    const { token } = useAuth();



    let handleOnClick = () => {

        let data = {"name": name, 
                    "quantity": parseFloat(quantity),
                    "projectmodellink": uuid,
                    "unit": unit,
                    "note": note,
                    "itemnr": itemnr,
                    "unitprice": parseFloat(unitprice),
                    "totalprice": parseFloat(totalprice)
                  };

        console.log(data);

        let url = API_URL + "/api/bautagebuch/positionen/" + uuid;

        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(data),
        };
        
        fetch(url, requestOptions)

          .then((response) => {
            if (!response.ok) {
              throw new Error('Fehler beim Senden der Daten');
            }
            alert("Positionsdaten erfolgreich geschrieben");
            return response.json();
          });
        }



    return(
        <>
        <h3>Neue Position</h3>

        <TextField
                    id="outlined-multiline-static"
                    label="Positionsnummer"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => setitemnr(event.target.value)}
                    />


        <TextField
                    id="outlined-multiline-static"
                    label="Name"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => setname(event.target.value)}
                    />

        
        <TextField
                    id="outlined-multiline-static"
                    label="Beschreibung"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => setnote(event.target.value)}
                    />


        <TextField
                    id="outlined-multiline-static"
                    label="Anzahl"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => setquantity(event.target.value)}
                    />

                
        <TextField
                    id="outlined-multiline-static"
                    label="Einheit"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => setunit(event.target.value)}
                    />


        <TextField
                    id="outlined-multiline-static"
                    label="Einheitspreis"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => setunitprice(event.target.value)}
                    />


        <TextField
                    id="outlined-multiline-static"
                    label="Gesamtpreis"
                    multiline
                    rows={1}
                    sx={{ width: '100ch' }}
                    defaultValue=""
                    onChange={event => settotalprice(event.target.value)}
                    />


        <br />

        <Button variant="contained" onClick={handleOnClick}>Eintragen</Button>
        </>
    );



}


export default PositionenNew;