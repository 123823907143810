import './StreetMap.css';

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';

import L from 'leaflet';

// Imports Icons
import InsightsIcon from '@mui/icons-material/Insights';

// Imports Eigene
import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';



function MapComponent() {

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const { token } = useAuth();

  const [position, setPosition] = useState([53.751741, 7.542730]); // [50.814205377738304, 6.766852271163662]



  const STATIC_PATH = "/images/";
  var icon = "marker-icon-2x-green.png";

  var color = "#000000";


  const svgHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-flag-fill" viewBox="0 0 16 16">
  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
</svg>`;

  const svgHTML2 = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
</svg>`;

const svgHTMLpicture = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="`+color+`" class="bi bi-file-image-fill" viewBox="0 0 16 16">
  <path d="M4 0h8a2 2 0 0 1 2 2v8.293l-2.73-2.73a1 1 0 0 0-1.52.127l-1.889 2.644-1.769-1.062a1 1 0 0 0-1.222.15L2 12.292V2a2 2 0 0 1 2-2zm4.002 5.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
  <path d="M10.564 8.27 14 11.708V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-.293l3.578-3.577 2.56 1.536 2.426-3.395z"/>
</svg>`;

  const customMarkerIcon = L.divIcon({
    className: "my-custom-pin",
    html: svgHTML2,
    iconSize: [80, 80],
    iconAnchor: [0, 0], // Punkt des Icons, der auf die genaue Position auf der Karte zeigt
    popupAnchor: [15, -20] // Punkt, relativ zu iconAnchor, an dem das Pop-up geöffnet wird
  });

  const customMarkerIconPicture = L.divIcon({
    className: "my-custom-pin2",
    html: svgHTMLpicture,
    iconSize: [80, 80],
    iconAnchor: [0, 0], // Punkt des Icons, der auf die genaue Position auf der Karte zeigt
    popupAnchor: [15, -20] // Punkt, relativ zu iconAnchor, an dem das Pop-up geöffnet wird
  });


  const path = [
    [50.81901813253374, 6.765232216887164],
    [50.81501483433338, 6.765924317622093],
    [50.815268750226295, 6.7695894384136395],
    [50.814598409280556, 6.774701317412377]
  ];

  const picturePos = [50.81501483433338, 6.765924317622093];

  if (position){

    return (
        <div style={{ width: '1000px', height: '600px' }}>

        <MapContainer center={position} zoom={12} style={{ height: "100%", width: "100%" }}>
            <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

        </MapContainer>
        </div>
    );}
        else {

            return(
            <div><InsightsIcon ></InsightsIcon><br />Keine Daten zum Anzeigen vorhanden.</div>)

        }
}

export default MapComponent;