import * as React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Link } from "react-router-dom";


export default function AddLinkButton(props) {

  // console.log(link)

  return (

    <Stack direction="row" alignItems="center" spacing={1}>

    <Link to={props.link}>

      <IconButton aria-label="delete" size="large">
        <AddCircleIcon fontSize="inherit" />
      </IconButton>

    </Link>

    </Stack>

      );
    }