import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import StreetMap from '../elements/StreetMap_002'; 


function EditDialog(props) {

  const { uuid } = useParams(); 


  const mapIsReadyCallback = (map) => {
    console.log(map);
  };

  
      return (
        < StreetMap />
      );
    }


export default EditDialog;