import React, { Component, useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";


import axios from 'axios';


import { AutoForm, AutoFields, ErrorsField, SubmitField } from 'uniforms-mui-5';

// https://blog.logrocket.com/quickly-build-schema-based-forms-in-react-with-uniforms/

import { bridge as schema } from './ProjectSchema';

import { useHistory } from 'react-router-dom'; // Hystory Hook
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';

 

function DataForm () {

    const { token } = useAuth();
    // console.log(token);

    // const history = useHistory(); // Hier holen wir den useHistory Hook
    const navigate = useNavigate(); 


    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

    const ENDPOINT_URL = "/api/projects/";

    //const [name, setName] = useState("");
    //const [description, setDescription] = useState("");
    //const [adress, setAdress] = useState("");
    // const [data, setData] = useState([]);

    //    name = models.TextField(max_length=100, null=False, blank=False)
    // note = models.TextField(max_length=3000, null=True, blank=True)
    // adress = models.TextField(max_length=3000, null=True, blank=True)


    const handleOnSubmit = async (dataForm) => {
            

        const Data = dataForm;

        try {
            const response = await axios.post(API_URL+ENDPOINT_URL, Data, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            // console.log(response.data);
            alert("Das Projekt wurde erfolgreich angelegt");

            navigate('/ProjectList');

        } catch (error) {
            console.error("Fehler beim Erstellen eines Projekts:", error);
        }
    };
       






    return (
        <>
            <h3>Neues Projekt erstellen</h3>

            <AutoForm schema={schema} onSubmit={(dataForm) => {handleOnSubmit(dataForm)}}>
              <AutoFields />
              <ErrorsField />
              <SubmitField />
            </AutoForm>

    </>
    );

};


  
  export default DataForm;