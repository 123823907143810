import * as React from 'react';
import { Link, useParams } from "react-router-dom";

// import { useProjectContext } from '../../hooks/useProject';

// import Liste from "../elements/ListeBautagebuchVer002";
import Universaltable from '../elements/universaltable_002';
import AddButton from "../elements/ButtonAddnew";

import { useProjectContext } from '../../hooks/ProjectContext';

// https://stackoverflow.com/questions/53219113/where-can-i-make-api-call-with-hooks-in-react

function BautagebuchListe() {

    const { uuid } = useParams(); // wird übergeben
   

    let rows = [
        {label : "Datum",
        field : "date",
        type: "date"},
        {label : "Vorkomnisse",
        field : "vorkommnisse"},];

        // {`/api/ifcelements/${uuid}`}

    return (
        <>        
        <h2>Einträge im Bautagebuch</h2>

        <Universaltable rows={rows} uuid={uuid} url={`/api/bautagebuch/${uuid}`} linkurl={`/BautagebuchLink/${uuid}`} titel="Bautagebuch Elements"/>
        <AddButton link={`/bautagebuchNew/${uuid}`} />
        </>
    );

};

export default BautagebuchListe;