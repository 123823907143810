import "gantt-task-react/dist/index.css";

import React, { useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";

import InsightsIcon from '@mui/icons-material/Insights';

import { Gantt, Task, ViewMode } from "gantt-task-react";

// import { getStartEndDateForProject, initTasks } from "./Helper.jsx";
 
import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';
 

export default function GanttAPP () {

    const { uuid } = useParams();
    // console.log(uuid);

    const { token } = useAuth();

    const [tasksList, setTasksList] = useState();


    const ENDPOINT_URL = "/api/tasks/endpoint/" + uuid;

    /**
    let tasks = [      {
        start: new Date(),
        end: new Date(),
        name: "Projekt 1",
        id: "ProjectSample",
        progress: 25,
        type: "project",
        hideChildren: false,
        displayOrder: 1,
      },] */


      // Beispieldatensätze
      /**
      let tasks2 = [ {
            "id": 7,
            "dependencies": 0,
            "projectID": 1,
            "name": "weee",
            "start": new Date("2023-04-09"),
            "end": new Date("2023-04-16"),
            "type": "task",
            "duration": 3,
            "progress": 10,
            "displayOrder": 0,
            "note": "f33"
        },]

        let tasks3 = [{"id":7,"dependencies":0,"projectID":1,"name":"weee",
                    "start":new Date("2023-04-09"),"end":new Date("2023-04-16"),"type":"task",
                    "duration":3,"progress":10,"displayOrder":0,"note":"f33"},
                    
                    {"id":8,"dependencies":0,"projectID":1,"name":"vevr",
                    "start":new Date("2023-04-02"),"end":new Date("2023-04-03"),"type":"task",
                    "duration":4,"progress":10,"displayOrder":0,"note":"t545"},
                    
                    {"id":9,"dependencies":0,"projectID":1,"name":"ferwewf", 
                    "start":new Date("2023-04-02"),"end":new Date("2023-04-09"),"type":"task",
                    "duration":0,"progress":0,"displayOrder":0,"note":"weffew"},
                    
                    {"id":10,"dependencies":0,"projectID":1,"name":"fdbedb",
                    "start":new Date("2023-04-09"),"end":new Date("2023-04-17"),"type":"task",
                    "duration":3,"progress":0,"displayOrder":0,"note":"erbrebre"}]
                     */


      useEffect(() => {

        
        const getData = async () => {
  
                FetchAPIData(ENDPOINT_URL, {}, token).then(response => {
  
                    let tasksarray = response.datas;

                    for (var i = 0; i < tasksarray.length; i++) {
                        tasksarray[i].start = new Date(tasksarray[i].start);
                        tasksarray[i].end = new Date(tasksarray[i].end);
                    }

                    setTasksList(tasksarray);
                    console.log("tasksarray");
                    console.log(tasksarray);
                  
                  });
        }
  
        getData();
  
    }, []);
    

    return (
        <>
        
        <h3>Gantt</h3>

        {tasksList ? 

        <Gantt
            tasks={tasksList}
            viewMode = {ViewMode.Month}
        >
        </Gantt>
        
:       <div><InsightsIcon ></InsightsIcon><br />Keine Daten zum Anzeigen vorhanden.</div>}

        </>
    );

}