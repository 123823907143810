import * as React from 'react';
import { Link, useParams } from "react-router-dom";

import Liste from "../elements/ListeIFCelementsVer001";
import Universaltable from '../elements/universaltable_002';

import AddButton from "../elements/ButtonAddnew";

import { useProjectContext } from '../../hooks/ProjectContext';

// https://stackoverflow.com/questions/53219113/where-can-i-make-api-call-with-hooks-in-react

function IFCelementsListe(props) {

    console.log(props);

    // const { projectUUID } = useProjectContext();
    const { uuid } = useParams(); // wird übergeben


    //console.log("IFCelementsListe");
    //console.log(uuid);

    let rows = [
                {label : "IFC Name",
                field : "ifcName"},
                {label : "IFC Typ",
                field : "ifcType"},
                {label : "Beschreibung",
                field : "ifcDescription"},];
 
    return (
        <>        
        <h2>IFC Elemente</h2>

        <Universaltable rows={rows} uuid={uuid} url={`/api/ifcelements/${uuid}`} linkurl={`/ifcelementLink/${uuid}`} titel="IFC Elements"/>
        <AddButton link={`/ifcelementNew/${uuid}`} />
        </>
    );

};

export default IFCelementsListe;