import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';

import { Link } from "react-router-dom";

import { useAuth } from '../../hooks/AuthContext';

function Header () { 

    const { logout } = useAuth();

    return (

        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary">

          <Toolbar>

            <img src="/images/bredic-logo.png" alt="SVG as an image" width="150px"></img>
            

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ flexGrow: 1 }} />
            <Link to="ProjectList">
            Projekte
            </Link>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
              >
                <LogoutIcon onClick={logout} style={{ cursor: 'pointer' }} />
              </IconButton>
            </Box>

            


            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-haspopup="true"
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>

            
          </Toolbar>
        </AppBar>
        </Box>


    );

}

export default Header;