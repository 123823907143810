import React from 'react';
import { Outlet } from "react-router-dom";
import { Grid, Paper } from '@mui/material';

import Header from "./LayoutHeader_002";
import Navigation from "./LayoutNavigation";


import { createTheme, ThemeProvider, styled } from '@mui/material/styles';


function Layout () {


  // console.log(authData);


  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#ffffff',
        darker: '#053e85',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
    },
  });



    return (

      <ThemeProvider theme={theme}>

        <Grid container direction="column" 
            style={{ minHeight: '100vh' }} 
            container rowSpacing={4} 
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
        <Grid item md={12}>
          
          <Paper>

            <Header />

          </Paper>

        </Grid>


        <Grid item container style={{ flexGrow: 1 }}>

        <Grid item xs={1} sm={1} md={1}>

<Paper>
</Paper>

</Grid>

          <Grid item xs={2} sm={2} md={2}>

            <Paper>

                <Navigation />
                
            </Paper>


          </Grid>
          
          <Grid
          item xs={8} sm={8} md={8}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '10vh' }}
        >

          <Paper elevation={0}>

              <Outlet />

          </Paper>

          </Grid>
        </Grid>
      </Grid>

      </ThemeProvider>
      );

    


};

export default Layout;