import React, { useState, useEffect} from 'react';

// import { useContext } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { redirect } from "react-router-dom";


// Authentifizierung
import { useAuth } from './hooks/AuthContext';


// Import der Componenten
import Layout from "./components/layouts/Layout";
import AdminMainView from "./apps/admin/components/outlets/AdminMain";

import Default from "./components/outlets/Default_002.js";

import Login from "./components/elements/Logindialog_004";

//import ProjectNew from "./components/outlets/ProjectNew";
import ProjectNew from './components/outlets/ProjectNewAutoform';
import ProjectList from "./components/outlets/ProjectList";

import BautagebuchNew from "./components/outlets/BautagebuchNew003";
import BautagebuchListe from "./components/outlets/BautagebuchListe_002";
import BautagebuchDetail from "./components/outlets/BautagebuchDetail";
import BautagebuchEdit from "./components/outlets/BautagebuchEdit";
import BautagebuchLink from "./components/outlets/BautagebuchLink";

import PositionenListe from "./components/outlets/Positionen";
import PositionenNew from "./components/outlets/PositionenNew";
import PositionenDetail from "./components/outlets/PositionenDetail_003";

import TaskNew from "./components/outlets/TasksNew";
import TaskList from "./components/outlets/TasksList002";
//import TaskGantt from "./components/outlets/TasksGantt";
import TaskGantt from "./components/outlets/TasksNewGantt_002";

import IFCelementsListe from "./components/outlets/IFCelements002";
import IFCelementNew from "./components/outlets/IFCelementsEdit003";

//
import VerlinkungEintraege from './components/outlets/VerlinkungEintraege';

import Streetmap from "./components/outlets/GIS";

import Webcam from "./components/outlets/Webcam";
import BIM from "./components/outlets/BIM";

import SVGviewer from "./components/outlets/SVGbetrachter";
import ThreeImageBetrachter from "./components/outlets/picturestoicons_001.js";

import FileUpload from "./components/outlets/FileUpload_001.js";



function App() {

  const { isAuthenticated, login } = useAuth();
  const { token } = useAuth();

  // console.log("Überprüfen ob der Nutzende authentifiziert ist?");
  // console.log(isAuthenticated);
  // console.log(token);

  if (isAuthenticated) {

    return (

      <div className="App">

      
        <BrowserRouter>

        <Routes>

          <Route path="/" element={<Layout />}>

            <Route index element={<Default />} />
            <Route path="Login" element={<Login />} />

            <Route path="/bim/:uuid" element={<BIM />} />
            <Route path="/planviewer/:uuid" element={<ThreeImageBetrachter />} />

            <Route path="ProjectNew" element={<ProjectNew />} />
            <Route path="ProjectList" element={<ProjectList />} />

            <Route path="/ifcelementsListe/:uuid" element={<IFCelementsListe />} />
            <Route path="/ifcelementNew/:uuid" element={<IFCelementNew />} />

            <Route path="/ifcelementLink/:uuid/:id" element={<VerlinkungEintraege type="ifcElement"/>} />

            <Route path="/bautagebuchListe/:uuid" element={<BautagebuchListe />} />
            <Route path="/bautagebuchNew/:uuid" element={<BautagebuchNew />} />
            <Route path="/bautagebuchDetail/:uuid/:id" element={<BautagebuchDetail />} />
            <Route path="/BautagebuchLink/:uuid/:id" element={<VerlinkungEintraege type="BautagebuchEintrag"/>} />

            <Route path="/myfileupload/:uuid" element={<FileUpload />} />

            <Route path="/positionenListe/:uuid" element={<PositionenListe />} />
            <Route path="/positionenNew/:uuid" element={<PositionenNew />} />
            <Route path="/positionDetail/:uuid/:id" element={<PositionenDetail />} />
            <Route path="/positionLink/:uuid/:id" element={<VerlinkungEintraege type="LeistungsPositionen"/>} />

            <Route path="/taskList/:uuid" element={<TaskList />} />
            <Route path="/taskNew/:uuid" element={<TaskNew />} />
            <Route path="/taskGantt/:uuid" element={<TaskGantt />} />
            <Route path="/taskLink/:uuid/:id" element={<VerlinkungEintraege type="tasks"/>} />

            <Route path="/gis/:uuid" element={<Streetmap />} />

         
           
          </Route>

        </Routes>

        </BrowserRouter>
      

    </div>
    );}

  else {

    return(
      <>
        <Login />
      </>
    );

  }

  return (

    <div className="App">

    
      <BrowserRouter>

      <Routes>

        <Route path="/" element={<Layout />}>

          <Route index element={<Default />} />

          <Route path="/bautagebuchEdit/:uuid/:id" element={<BautagebuchEdit />} />

        

        
      

          <Route path="/webcam/:uuid" element={<Webcam />} />


          
          <Route path="click" element={<Default />} />
          <Route path="todo" element={<Default />} />
          <Route path="betreuer" element={<Default />} />

        
        </Route>

        <Route path="/betreuer/" element={<Layout />}>

          <Route index element={<AdminMainView />} />
          
        </Route>

      </Routes>

      </BrowserRouter>
    

  </div>
  );
}



export default App;
