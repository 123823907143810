import React, { useState, useEffect } from 'react';

//REDUX
// import { useSelector } from 'react-redux';


import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ProjectNavigation from "./ProjectNavigation_002";

/**
 * Hooks
 */
import { useProjectContext } from '../../hooks/ProjectContext';


/**
 * Output
 */
const Accordion = styled((props) => (

  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));


const AccordionSummary = styled((props) => (

  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



export default function CustomizedAccordions(props) {

  const { projectUUID, projectsList } = useProjectContext();  // Hohlen der aktuellen HauptProjekt UUID und der ganzen
                                                              // Proejtkliste
                                                              // Kann man vermutliuch viel erleganter lösen!
  const { setProjectsRenew } = useProjectContext();           // Hier wird die Projektliste aktualisiert
  

  const [ expanded, setExpanded ] = useState(projectUUID);


  // Wenn auf das Panel gelicked wird, so soll es sich ausklappen
  const handleChange = (panel) => (event, newExpanded) => {

    setExpanded(newExpanded ? panel : false);
  
  };


  // Aktualisieren der Projektsliste
  useEffect(() => {
    // Setzen von projectsRenew auf true, um die Aktualisierung der Projekte auszulösen
    setProjectsRenew(true);
  }, []);



  return (
    <div>

    {projectsList && projectsList.map((project) => (

      <Accordion expanded={expanded === "panel"+project.id} onChange={handleChange("panel"+project.id)}>
        
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        
          <Typography>{ project.name }</Typography>
        </AccordionSummary>
        
        <AccordionDetails>
          

            <ProjectNavigation project={ project } />


        </AccordionDetails>
      </Accordion>

  ))}

    </div>
  );
}