import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

// Erstelle den Context

const AuthContext = createContext();


// Erstelle einen Provider, um den Context für die Komponenten bereitzustellen
export const AuthProvider = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  // Beim Laden der Seite versuche, ein gespeichertes Token aus dem localStorage zu laden
  useEffect(() => {

    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }
    
  }, []);

  const login = async (username, password) => {

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    let ENDPOINT_URL = "/accounts/login/api/";

    try {
        const response = await axios.post(API_URL+ENDPOINT_URL, {
        username,
        password,
        });

        // Setze isAuthenticated auf true, wenn die Anmeldung erfolgreich ist
        setIsAuthenticated(true);

        // Speichere das Token im Zustand und im localStorage
        setToken(response.data.token);
        localStorage.setItem('authToken', response.data.token);

    } catch (error) {
        console.error('Login failed', error);
    }
    };

    const logout = () => {
    // Lösche das Token und setze den Status zurück
    setToken(null);
    setIsAuthenticated(false);
    localStorage.removeItem('authToken');
    };

    return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
        {children}
    </AuthContext.Provider>
    );
};

// Erstelle einen Hook, um den Authentifizierungs-Context einfach verwenden zu können
export const useAuth = () => {
    return useContext(AuthContext);
};