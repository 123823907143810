import React from 'react';
import TextField from '@mui/material/TextField';
import { connectField } from 'uniforms';

const NumberInput = ({ onChange, value, ...props }) => {
  const handleInputChange = (event) => {
    const parsedValue = parseInput(event.target.value);
    onChange(parsedValue);
  };

  const parseInput = (input) => {
    const cleanedInput = input.replace(/[^0-9.]/g, '');
    const decimalPointIndex = cleanedInput.indexOf('.');
    if (decimalPointIndex !== -1) {
      const integerPart = cleanedInput.substring(0, decimalPointIndex + 1);
      const decimalPart = cleanedInput.substring(decimalPointIndex + 1).replace(/\./g, '');
      return integerPart + decimalPart;
    }
    return cleanedInput;
  };

  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      {...props}
    />
  );
};

export default connectField(NumberInput);