import React, { Component } from "react";

class Default extends Component {

    render() {

        return (

            <div>

                Herzlich willkommen, bisher wurden<br />keine Inhalte für die Hauptseite festgelegt.<br /><br />
                
            </div>

        );

    }


}

export default Default;