// apicall.js
import axios from 'axios';

// import { useAuth } from '../hooks/AuthContext'; // Das geht leider nicht

/**
 * Sendet eine GET-Anfrage an das Backend.
 *
 * @param {string} backendUrl - Die URL des Backends.
 * @param {Object} [params] - Optional. Parameter für die GET-Anfrage im JSON-Format.
 * @returns {Promise} - Eine Promise mit der Antwort des Backends.
 */


export const FetchAPIData = (backendUrl, params = {}, token) => {

    const API_URL = process.env.REACT_APP_BACKEND_URL;

    const headers = {};

    // Wenn ein Token bereitgestellt wird, fügen Sie es dem Authorization Header hinzu
    if (token) {

        headers.Authorization = `Token ${token}`;

        /*
        let data =  axios.get(API_URL+backendUrl, { params, headers })

            .then(response => response.data)

            .catch(error => {

                throw error;

            });
        */

        const options = {
              url: API_URL+backendUrl,
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
              }
            };


       let data = axios(options)
                  .then(response => response.data)

                  .catch(error => {
      
                      throw error;
      
                  });

        return(data);
    }
    else {
        return false;
    }
};
