// UserFilesList.js

import React, { useState, useEffect } from 'react';


import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';

import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';

const renderFileIcon = (filetype) => {
    switch (filetype) {
      case 'pdf':
        return <PictureAsPdfIcon />;
      case 'jpg':
      case 'png':
        return <ImageIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

 
const UserFilesList = ({ projectUUID }) => {
  
  const { token } = useAuth();

  const [userFiles, setUserFiles] = useState([]);

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  let url = "/api/files/get/"+projectUUID;


  useEffect(() => {

        
    const getData = async () => {

        console.log("UserFilesList");      
        console.log(url);

            FetchAPIData(url, {}, token).then(response => {

              setUserFiles(response);
              console.log("UserFilesList");
              console.log(response);
              // console.log(userFiles);
              
              });
    }

    getData();

}, []);




  return (
    <ul>
    {userFiles && userFiles.map(file => (
        <ListItem key={file.id}>
            <a href={ API_URL + file.file } target="_new">
        <ListItemIcon>
            {renderFileIcon(file.filetype)}
        </ListItemIcon>
        <ListItemText primary={file.name} />
            </a>
        </ListItem>
    ))}
    </ul>
  );
};

export default UserFilesList;