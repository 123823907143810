import * as React from 'react';
import { Link, useParams } from "react-router-dom";

import Liste from "../elements/BautagebuchListePositionen";
import Universaltable from '../elements/universaltable_002';
import AddButton from "../elements/ButtonAddnew";

// https://stackoverflow.com/questions/53219113/where-can-i-make-api-call-with-hooks-in-react

function BautagebuchListe() { 

    const { uuid } = useParams(); 

    let rows = [
        {label : "PosNr.",
        field : "itemnr"},
        {label : "Name",
        field : "name"},];

    return (
        <>        
        <h2>Positionen</h2>

        <Universaltable rows={rows} url={`/api/bautagebuch/positionen/${uuid}`} linkurl={`/positionLink/${uuid}`} titel="Positionen Elements"/>
        <AddButton link={`/positionenNew/${uuid}`} />
        </>
    );
 
};

export default BautagebuchListe;