
import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'

import { FBXLoader } from 'three-stdlib/loaders/FBXLoader';
import { GLTFLoader } from 'three-stdlib/loaders/GLTFLoader';

import Tooltip from '@mui/material/Tooltip';

import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HandymanIcon from '@mui/icons-material/Handyman';
import FactoryIcon from '@mui/icons-material/Factory';




function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (mesh.current.rotation.x += delta))
  // Return view, these are regular three.js elements expressed in JSX
  
  
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  );
};


function useFBXLoader(url) {
  const [model, setModel] = useState();

  useEffect(() => {
    const loader = new FBXLoader();
    loader.load(url, (object) => setModel(object));
  }, [url]);

  return model;
}


function useGLTFLoader(url) {
  const [model, setModel] = useState();

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(url, (gltf) => setModel(gltf.scene));
  }, [url]);

  //console.log("3D Model");
  //console.log(model);

  return model;
}


function Model({ url }) {
  //const model = useFBXLoader(url);
  const model = useGLTFLoader(url);

  return model ? <primitive object={model} /> : null;
}


function Symbols(props) {


    if (props) {

        if(props.type.type == "site") {

          var Symbol = () => {
            return(
              <HandymanIcon color="primary">
                <ThreeDRotationIcon fontSize="large" />
              </HandymanIcon>);
          };
        }

        else if (props.type.type == "headquarter") {

          var Symbol = () => {
            return(
              <AccountBalanceIcon color="primary">
                <ThreeDRotationIcon fontSize="large" />
              </AccountBalanceIcon>);
          };
        }

        else if (props.type.type == "factory") {

          var Symbol = () => {
            return(
              <FactoryIcon color="primary">
                <ThreeDRotationIcon fontSize="large" />
              </FactoryIcon>);
          };
        };


      // console.log(Symbol);

      return(
          <div
          style={{
            position: 'absolute',
            top: props.x,
            left: props.y,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
      
        <Tooltip title="Text, Text, Text, Text" arrow>
          <div>
                       
          <Symbol></Symbol>
          { props.name }
          </div>
          </Tooltip>

        </div>)}};



function Overlay(props) {

    /**
     * {props.elements && props.elements.map((row) => {
        console.log(row);
        console.log(row.location.x);
    })};*/

  return (
    <>
        {props.elements && props.elements.map((element) => (
        <Symbols x={ element.location.x } y= { element.location.y } name={ element.name } type={element.type}></Symbols>
    ))};

    </>
  );
}



function THREEviewer() {

    const API_URL = process.env.REACT_APP_BACKEND_URL;
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const NODE_ENV = process.env.NODE_ENV; // Komischerweise funktionioert dieses????

    // console.log("URL: " + API_URL+"/api/thing/");

    const [baustellenList, setBaustellenList] = useState(null);

    useEffect(() => {
        const getData = async () => {

            let url = API_URL+"/api/thing/";
            let meinToken = "01d7e68840840fe0b9baeae28ca96dad283f4b0d"

            await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${meinToken}`,
              },
            })
                .then( resp => resp.json()) 
                .then( data => {
                    console.log(data.datas);
                    setBaustellenList(data.datas);
                });
        }

        /**
         * fetch('https://meine-api-url.de/api/meine-endpoint', {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Token ${meinToken}`,
  },
})
         */

        getData();

    }, []);


        return (
        <div style={{ position: 'relative', width: '950px', height: '600px' }}>
        
        <Canvas camera={{ position: [0, 1.5, 0] }}>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <Model url="/models/Erftstadt_Ver001.glb" />
            
        </Canvas>
        <Overlay elements={baustellenList} />
        </div>
        );

};

/**
 *             <Box position={[-2, 2, 0]} />
            <Box position={[2, 2, 0]} />
            <Box position={[-1.2, 0, 0]} />
            <Box position={[1.2, 0, 0]} />
            <Box position={[0, -1.5, 0]} />
 */



export default THREEviewer;