import React, { createContext, useState, useContext, useEffect } from 'react';

import { useAuth } from './AuthContext';

import { FetchAPIData } from '../services/apiservices_001';

// Erstelle den Context

const ProjectContext = createContext(); 

 
export const ProjectProvider = ({ children }) => {

    const { token } = useAuth(); 
    // console.log(token);


    const [ actualProject, setactualProject ] = useState(false);
    const [ projectUUID, setProjectUUID ] = useState(false);
    const [ projectCoordinates, setProjectCoordinates] = useState({ latitude: false, longitude: false });
    const [ projectsList, setProjectsList ] = useState([]);
    const [ projectsRenew, setProjectsRenew ] = useState(false);

    // const API_URL = process.env.REACT_APP_BACKEND_URL;
    
    // useEffect, der ausgeführt wird, wenn sich projectsRenew ändert
    useEffect(() => {

        if (projectsRenew && token) {

          console.log("Im UseEffekt");
    
          // Hier können Sie Ihren API-Aufruf machen, um die Projekte zu erneuern
          FetchAPIData('/api/projects', {}, token).then(response => {

            let data = response;

            setProjectsList(data); // Aktualisieren der Projekte
            console.log(response);
            setProjectsRenew(false); // Setzen Sie projectsRenew zurück, um weitere Aufrufe zu verhindern, bis es wieder benötigt wird
          
            });
        }
      }, [projectsRenew, token]);

    return (
        <ProjectContext.Provider value={{  actualProject, setactualProject,
                                        projectUUID, setProjectUUID,
                                        projectCoordinates, setProjectCoordinates,
                                        projectsList, setProjectsList,
                                        projectsRenew, setProjectsRenew,
                                        
                                        }}>
            {children}
        </ProjectContext.Provider>
    );

}


// Erstelle einen Hook, um den Authentifizierungs-Context einfach verwenden zu können
export const useProjectContext = () => {
    return useContext(ProjectContext);
};