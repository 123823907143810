import React, { useState, useEffect } from 'react';

import { Link, useParams } from "react-router-dom";

import axios from 'axios';

// import { useProjects } from '../../hooks/useProjects';


import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Tooltip from '@mui/material/Tooltip';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NativeSelect from '@mui/material/NativeSelect';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';

import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';




import dayjs from 'dayjs';

// Imports Eigene
import { useAuth } from '../../hooks/AuthContext';
import { FetchAPIData } from '../../services/apiservices_001';



function formatDate(date) {
    const format = 'DD.MM.YYYY'; // gewünschtes Format
    return dayjs(date).format(format);
  }




function LinkDialog (props) {

    const { token } = useAuth();
    const API_URL = process.env.REACT_APP_BACKEND_URL;


    // Dieses sind die Infos die Angezeigt werden, unabhängig 
    // von der Verlinkung. Wobei die ID auch wichtig für die
    // Verlinkung ist

    // const [elementDetails, setElementDetails] = useState(null);

    // Nun werden die States für die Verlinkungen aufgebaut
    const [titel, setTitel] = useState("Verlinkung");
    
    const [ifcElementList, setIfcElementList] = useState(null);
    const [ifcElementSelected, setIfcElementSelected] = useState(null);


    const [bautagebuchList, setBautagebuchList] = useState(null);
    const [bautagebuchSelected, setBautagebuchSelected] = useState(null);


    const [positionenList, setPositionenList] = useState(null);
    const [positionSelected, setPositionSelected] = useState([]);

    const [tasksList, setTasksList] = useState(null);
    const [tasksSelected, setTasksSelected] = useState([]);    


    const [Linking, setLinking] = useState(null);
    const [LinkingEntry, setLinkingEntry] = useState(null);

    
    //const {actualProject, setactualProject} = useProjects();

    const { id } = useParams(); 
    const { uuid } = useParams(); 

    let urlEndpoint;

    useEffect(() => {


        // console.log("PROPS");
        // console.log(props);


        switch(props.type) {

            case "ifcElement":
                setTitel("IFC Verlinkung");
                setIfcElementSelected(id);
                break;
            case "BautagebuchEintrag":
                setTitel("Bautagebuch Verlinkung");
                setBautagebuchSelected(id);
                break;
            case "LeistungsPositionen":
                setTitel("Positionen Verlinkung");
                setPositionSelected(id);
                break;
            case "tasks":
                setTitel("Tasks Verlinkung");
                setTasksSelected(id);
                break;
        }

    }) // Ende Use Effict
    

    let rows = [
        {label : "IFC Name",
        field : "ifcName"},
        {label : "IFC Typ",
        field : "ifcType"},
        {label : "Beschreibung",
        field : "ifcDescription"},];

    
    //setactualProject(1);

    let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization': `Token ${token}` }, 
    };



    useEffect(() => {

        let urlEndpoint = "/api/linking/"+uuid;
        
        const getData = async () => {

            let url = API_URL+urlEndpoint;

            console.log("Abrufen der Links");
            console.log(url);

            await fetch(url, requestOptions)
                .then( resp => resp.json()) 
                .then( data => {
                    // console.log("API Bautagebuch Datas: ");
                    // console.log(data.datas);
                    setLinking(data.datas);
                });
        }

        getData();
        

    }, []);

    /**
     * Nun werden die States aufgebaut für die Verlinkung
     */

    // Einträge aus dem IFC Elementen werden eingelesen
    useEffect(() => {

        let urlEndpoint = "/api/ifcelements/"+uuid;

        
        const getData = async () => {

            let url = API_URL+urlEndpoint;
            //console.log(url);

            await fetch(url)
                .then( resp => resp.json()) 
                .then( data => {
                    setIfcElementList(data.datas); // Individueller Teil
                });
        }

        getData();
        // console.log(positionenList);

    }, []);


    // Einträge aus dem Bautagebuch werden eingelesen
    useEffect(() => {

        let urlEndpoint = "/api/bautagebuch/endpoint/"+uuid;

         
        const getData = async () => {

            let url = API_URL+urlEndpoint;
            //console.log(url);

            await fetch(url)
                .then( resp => resp.json()) 
                .then( data => {
                    setBautagebuchList(data.datas); // Individueller Teil
                });
        }

        getData();
        // console.log(positionenList);

    }, []);


    // Positionslistze wird eingelesen
    useEffect(() => {

        let urlEndpoint = "/api/bautagebuch/positionen/"+uuid;
        
        const getData = async () => {

            let url = API_URL+urlEndpoint;
            //console.log(url);

            await fetch(url)
                .then( resp => resp.json()) 
                .then( data => {
                    setPositionenList(data.datas); // Individueller Teil
                });
        }

        getData();
        // console.log(positionenList);

    }, []);


    // Tasks Datensätze werden eingelesen
    useEffect(() => {

        let urlEndpoint = "/api/tasks/endpoint/"+uuid;
        
        const getData = async () => {

            let url = API_URL+urlEndpoint;
            //console.log(url);

            await fetch(url)
                .then( resp => resp.json()) 
                .then( data => {
                    setTasksList(data.datas);
                });
        }

        getData();
        // console.log(positionenList);

    }, []);


    /**
     * Ende Aufbau der States
     */



    /**
     * Submit
     */

    const handleSubmit = async () => { 

        let urlEndpoint = "/api/linking/"+uuid;

        let url = API_URL+urlEndpoint;

        console.log("SUBMIT");
        console.log(url);

        let data = { "projectUUID": uuid,
                    "ifcElementID" : parseInt(ifcElementSelected, 10),
                    "BautagebuchEintragID": parseInt(bautagebuchSelected, 10),
                    "LeistungsPositionenID" : parseInt(positionSelected, 10),
                    "tasksID" : parseInt(tasksSelected, 10),
                    };

        console.log("Array für api - Call");
        console.log(data);


        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        };

        console.log(requestOptions);
 
        fetch(url, requestOptions)

            .then((response) => {
                if (!response.ok) {
                throw new Error('Fehler beim Senden der Daten');
                }
                return response.json();
            });

     
      }


    /**
     * Ende Submit
     */


    const handleDelete = async (id) => { 


        console.log("DELET");
        console.log(id);

        let url = API_URL+urlEndpoint;

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer Ihr-Token'  // Falls erforderlich
            },
            data: { id: id },  // Falls erforderlich
        })
        .then((response) => {
        console.log(response.data);
        })
        .catch((error) => {
        console.error('Error:', error);
        });
    };




    return(

        <>
        <Paper elevation={3} style={{ padding: '24px', maxWidth: '1000px', margin: '16px auto' }}>

                <Typography variant="h6" gutterBottom>{ titel }</Typography>
                <br />
               
                <Divider style={{ margin: '16px 0' }} />
                <Typography variant="body1" paragraph>Verknüpft mit Positionen:</Typography>


                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Verlinkung No</TableCell>
                        <TableCell align="right">IFC</TableCell>
                        <TableCell align="right">Tagebuch</TableCell>
                        <TableCell align="right">Position</TableCell>
                        <TableCell align="right">Task</TableCell>
                        <TableCell align="right">-</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>

                {Linking && Linking.map((linkentry) => (
                
                
                <TableRow
                key={linkentry.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {linkentry.id}
                </TableCell>
                <TableCell align="right">{linkentry.ifcElementID}</TableCell>
                <TableCell align="right">{linkentry.BautagebuchEintragID}</TableCell>
                <TableCell align="right">{linkentry.LeistungsPositionenID}</TableCell>
                <TableCell align="right">{linkentry.tasksID}</TableCell>
                <TableCell align="right"><Tooltip title="Verlinkung löschen"><DeleteOutlineIcon 
                                                         onClick={() => handleDelete(linkentry.id)}/></Tooltip>
                </TableCell>
                </TableRow>

                                                        
                                                        
                                                        
                                                        
                        ))}


                     </TableBody>
                    </Table>
                    </TableContainer>

                <Divider style={{ margin: '16px 0' }} />
                <Typography variant="body1" paragraph>Verknüpfungen hinzufügen:</Typography>

                <Box sx={{ minWidth: 120 }} 

                        component="form"
                        onSubmit={handleSubmit}>



                            <Typography variant="caption" paragraph>
                                Neue Verknüpfung mit den Einträgen der IFC Elemente
                            </Typography>


                    
                            <NativeSelect
                            defaultValue={0}
                            value={ifcElementSelected}
                            onChange={(event) => setIfcElementSelected(event.target.value)}
                            inputProps={{
                                name: 'ifcElementID',
                                id: 'uncontrolled-native',
                            }}
                            >
                            <option key={ 0 } value={ 0 }>-</option>
                                {ifcElementList && ifcElementList.map((item) => (
                                <option key={item.id} value={ item.id }>{ item.ifcType } - { item.ifcName } - { item.ifcID }</option>
                                ))}
                            </NativeSelect>

                            <Typography variant="caption" paragraph>
                                Neue Verknüpfung mit den Einträgen im Bautagebuch
                            </Typography>


                    
                            <NativeSelect
                            defaultValue={0}
                            value={bautagebuchSelected}
                            onChange={(event) => setBautagebuchSelected(event.target.value)}
                            inputProps={{
                                name: 'BautagebuchEintragID',
                                id: 'uncontrolled-native',
                            }}
                            >
                            <option key={ 0 } value={0}>-</option>
                                {bautagebuchList && bautagebuchList.map((item) => (
                                <option key={item.id} value={item.id}>{item.date}</option>
                                ))}
                            </NativeSelect>


                            <Typography variant="caption" paragraph>
                                Neue Verknüpfung mit den Einträgen in der Positionsliste
                            </Typography>


                    
                            <NativeSelect
                            defaultValue={0}
                            value={positionSelected}
                            onChange={(event) => setPositionSelected(event.target.value)}
                            inputProps={{
                                name: 'LeistungsPositionenID',
                                id: 'uncontrolled-native',
                            }}
                            >
                            <option key={ 0 } value={0}>-</option>
                                {positionenList && positionenList.map(( item ) => (
                                <option key={ item.id } value={ item.id }>{ item.itemnr } - { item.name }</option>
                                ))}
                            </NativeSelect>



                            <Typography variant="caption" paragraph>
                                Neue Verknüpfung mit den Einträgen aus den Tasks
                            </Typography>


                    
                            <NativeSelect
                            defaultValue={0}
                            value={tasksSelected}
                            onChange={(event) => setTasksSelected(event.target.value)}
                            inputProps={{
                                name: 'tasksID',
                                id: 'uncontrolled-native',
                            }}
                            >
                            <option key={ 0 } value={0}>-</option>
                                {tasksList && tasksList.map(( item ) => (
                                <option key={ item.id } value={ item.id }>{ item.id } - { item.name } - { item.start }</option>
                                ))}
                            </NativeSelect>


                    

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Hinzufügen
                    </Button>


                </Box>

        </Paper>
        </>

    );

}


export default LinkDialog;